import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import subscriptions from "./subscriptions";

const PagesProduct = ({ match }) => {
  return (
    < div >
      <Switch>
        {/* <Route path={`${match.url}/**`} component={(() => { return <Outlet branchKey={branchKey}></Outlet> })} /> */}
        <Route path={`${match.url}/`} component={subscriptions} />
        <Redirect to="/error" />
      </Switch>
    </div >
  )

};
export default PagesProduct;
