import React, { Component, Fragment } from 'react';
import { Row } from 'reactstrap';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import IconCardsCarousel from '../../../containers/dashboards/IconCardsCarousel';
import RecentOrders from '../../../containers/dashboards/RecentOrders';
import TopProviders from '../../../containers/dashboards/topProviders';
import TopWashers from '../../../containers/dashboards/topWashers';
import { connect } from "react-redux";
import SelectedServiceProvider from './components/SelectedServiceProvider';
import { firestore } from '../../../helpers/Firebase';
import moment from 'moment';
import { accessTypes, formatNumber } from '../../../helpers/Utils';

class DefaultDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      totals: [],
      bookings: [],
      selectedProvider: null,
      sortedProviders: false,
      sortedWashers: false,
      lastUpdated: moment().format("DD/MM/YYYY hh:mm A")
    }
  }
  getData() {
    let { providers, washers, authUser,totalProviders,totalWashers } = this.props
    let { totals, bookings, lastUpdated } = this.state
    let _this = this
    bookings = []
    let role = authUser?.user?.accessType || 'superAdmin'
    let ref = firestore().collection('newAnalytics')
    if (!accessTypes.includes(role)) {
      ref.doc("SP").get().then(function (snapshot) {
        let data = snapshot.data()[authUser?.user?.uid]
        totals = [

          { title: 'Total Washers', icon: "iconsminds-engineering", value: (totalWashers && Object.keys(totalWashers).length) || 0, color: "#00BCD4" },
          { title: 'Total Users', icon: "iconsminds-mens", value: formatNumber(data?.totalUsers) || 0, color: "#3F51B5" },
          { title: 'Total Sales', icon: "iconsminds-money-bag", value: formatNumber(data?.totalRevenu) || 0, color: "#278664" },
          { title: 'Average Rating', icon: "simple-icon-star", value: (data?.totalRate?.rate / data?.totalRate?.count).toFixed(2) || 0, color: "#FFE234" },
          { title: 'Total Bookings', icon: "iconsminds-basket-coins", value: formatNumber(data?.totalBookings) || 0, color: "#9C27B0" },
          { title: 'Total Online', icon: "simple-icon-check", value: formatNumber(data?.counters?.onlineBooking) || 0, color: "#4CAF50" },
          { title: 'Total Offline', icon: "simple-icon-power", value: formatNumber(data?.counters?.offlineBooking) || 0, color: "#dc3545" },
          { title: 'Total Canceled', icon: "simple-icon-close", value: formatNumber(data?.counters?.canceled) || 0, color: "#000000" },
          { title: 'Total Rejected', icon: "simple-icon-exclamation", value: formatNumber(data?.counters?.rejected) || 0, color: "#000000" },

        ]
        _this.setState({ totals, loading: false })
      })
      ref.doc("washer").get().then(function (snapshot) {
        let data = snapshot.data()
        let sorted = Object.keys(data).map(item => {
          return washers?.[item]?.sp_id === authUser?.user?.uid && { ...washers?.[item], ...data[item] }
        }).filter(rec => rec)
        _this.setState({ sortedWashers: sorted?.sort((a, b) => (a.totalBookings < b.totalBookings) ? 1 : ((b.totalBookings < a.totalBookings) ? -1 : 0)) })
      })

      firestore().collection('bookings').where('sp_id', '==', authUser?.user?.uid).orderBy('created', 'desc').limit(6).get().then(function (snapshot) {
        snapshot.docs.forEach(doc => {
          bookings.push(doc.data())
        })
        _this.setState({ bookings })

      })
      ref.doc("general").get().then(function (snapshot) {
        // let data = snapshot.data()
        // lastUpdated = moment(data.lastUpdate).format("DD/MM/YYYY hh:mm A")
        lastUpdated = moment().format("DD/MM/YYYY hh:mm A")
        _this.setState({ lastUpdated })
      })
    } else {
      ref.doc("general").get().then(function (snapshot) {
        let data = snapshot.data()
        // lastUpdated = moment(data.lastUpdate).format("DD/MM/YYYY hh:mm A")
        lastUpdated = moment().format("DD/MM/YYYY hh:mm A")
        totals = [
          { title: 'Total Service Providers', icon: "iconsminds-shop-4", value: totalProviders && Object.keys(totalProviders).length, },
          { title: 'Total Washers', icon: "iconsminds-engineering", value: (totalWashers && Object.keys(totalWashers).length) || 0, color: "#00BCD4" },
          { title: 'Total Users', icon: "iconsminds-mens", value: formatNumber(data?.totalUsers) || 0, color: "#3F51B5" },
          { title: 'Total Sales', icon: "iconsminds-money-bag", value: formatNumber(data?.totalRevenu) || 0, color: "#278664" },
          { title: 'Chill Revenue', icon: "iconsminds-dollar", value: formatNumber(data?.totalRevenu * 0.3) || 0, color: "#FF9F40" },
          { title: 'Total Bookings', icon: "iconsminds-basket-coins", value: formatNumber(data?.totalBookings) || 0, color: "#9C27B0" },
          { title: 'Total Online', icon: "simple-icon-check", value: formatNumber(data?.counters?.onlineBooking) || 0, color: "#4CAF50" },
          { title: 'Total Offline', icon: "simple-icon-power", value: formatNumber(data?.counters?.offlineBooking) || 0, color: "#dc3545" },
          { title: 'Total Canceled', icon: "simple-icon-close", value: formatNumber(data?.counters?.canceled) || 0, color: "#000000" },
          { title: 'Total Rejected', icon: "simple-icon-exclamation", value: formatNumber(data?.counters?.rejected) || 0, color: "#000000" },
        ];
        _this.setState({ totals, loading: false, lastUpdated })
      })
      ref.doc("SP").get().then(function (snapshot) {
        let data = snapshot.data()
        // let sorted = Object.keys(data)?.filter(r => data?.[r]?.totalBookings)?.sort((a, b) => data?.[b]?.totalBookings - data?.[a]?.totalBookings)?.map(item => {
        //   return providers?.[item] ? { ...providers?.[item], ...data[item] } : false
        // })?.slice(0, 6)
        let sorted = Object.keys(data)
          .filter(r => data?.[r]?.totalBookings && providers?.[r]?.active) // Check if the provider is active
          .sort((a, b) => data?.[b]?.totalBookings - data?.[a]?.totalBookings)
          .map(item => providers?.[item] ? { ...providers?.[item], ...data[item] } : false)
          .filter(Boolean) // Filter out any false values that might have been added
          .slice(0, 6); // Get the first 6 items
        _this.setState({ sortedProviders: sorted })

      })
      ref.doc("washer").get().then(function (snapshot) {
        let data = snapshot.data()
        // let sorted = Object.keys(data)?.filter(r => data?.[r]?.totalBookings)?.sort((a, b) => data?.[b]?.totalBookings - data?.[a]?.totalBookings)?.map(item => {
        //   return washers?.[item] ? { ...washers?.[item], ...data[item] } : false
        // })?.slice(0, 6)
        let sorted = Object.keys(data)
          .filter(r => data?.[r]?.totalBookings && washers?.[r]?.active) // Check if the provider is active
          .sort((a, b) => data?.[b]?.totalBookings - data?.[a]?.totalBookings)
          .map(item => washers?.[item] ? { ...washers?.[item], ...data[item] } : false)
          .filter(Boolean) // Filter out any false values that might have been added
          .slice(0, 6); // Get the first 6 items
        _this.setState({ sortedWashers: sorted })
      })
    }
  }

  componentDidUpdate(previousProps) {
    let { providers, washers } = this.props
    if (previousProps.providers !== providers || previousProps.washers !== washers) {
      this.getData()
    }
  }
  componentDidMount() {
    this.getData()
  }
  selectProvider(selectedProvider) {
    this.setState({ selectedProvider })
  }
  render() {
    let { authUser, washers } = this.props
    let { bookings, totals, selectedProvider, sortedProviders, sortedWashers, loading, lastUpdated } = this.state
    let _this = this
    if (selectedProvider) {
      return <SelectedServiceProvider authUser={authUser} provider={selectedProvider} washers={washers} selectProvider={(selectedProvider) => { this.selectProvider(selectedProvider) }} />
    }
    return (
      (
        loading ? (
          <div className="loading" />
        ) : (
          <Fragment>
            <div className="mb-2">
              <Row>
                <Colxx xxs="6">
                  <h1>Dashboard</h1>
                </Colxx>
                <Colxx xxs="6" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingRight: '40px' }}>
                  <div style={{ color: 'gray', fontSize: '14px', marginRight: '12px' }} >Last updated: {lastUpdated}</div>
                  <i className="simple-icon-refresh" style={{ cursor: 'pointer', fontSize: '18px', }} onClick={async () => {
                    this.setState({ loading: true })
                    // let result = await functions().httpsCallable('updateReports')();
                    // if (result) {
                    //   _this.getData()
                    // }

                    _this.getData()
                  }} />
                </Colxx>
              </Row>
            </div>
            <Separator className="mb-5" />
            <Row>
              <Colxx xxs="12" className="mt-2">
                {totals && totals.length && <IconCardsCarousel totals={totals} authUser={authUser} />
                }
              </Colxx>
            </Row>
            <Row>
              {!accessTypes.includes(authUser.user.accessType) &&
                <Colxx xxs="12" md="6" className="mb-4">
                  {bookings && <RecentOrders bookings={bookings} authUser={authUser} />}
                </Colxx>}

              {accessTypes.includes(authUser.user.accessType) &&
                <Colxx xxs="12" md="6" className="mb-4">
                  {sortedProviders && <TopProviders providers={sortedProviders} selectProvider={(selectedProvider) => { this.selectProvider(selectedProvider) }} />}
                </Colxx>}
              <Colxx xxs="12" md="6" className="mb-4">
                {sortedWashers && < TopWashers washers={sortedWashers} authUser={authUser} />}

              </Colxx>
            </Row>


          </Fragment>
        )

      ));
  }
}
const mapStateToProps = ({ providers, authUser }) => {
  let activeProviders = Object?.values(providers?.provider || {})?.reduce((acc, item) => {
    if (item.active) {
      acc[item.key] = item
    }
    return acc
  }, {})
  let activeWashers = Object?.values(providers?.washers || {})?.reduce((acc, item) => {
    if (item.active && activeProviders?.[item.sp_id]) {
      acc[item.key] = item
    }
    return acc
  }, {})
  return {
    authUser,
    providers: activeProviders || {},
    washers: activeWashers || {},
    totalProviders: providers?.provider || {},
    totalWashers: providers?.washers || {},
  }
};



export default connect(mapStateToProps)(DefaultDashboard);
