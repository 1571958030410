import Switch from "rc-switch";
import React, { Component, Fragment } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import OutletImg from '../../../assets/css/img/CarWashCenter.png';
import { Colxx } from "../../../components/common/CustomBootstrap";
import DataTablePagination from "../../../components/DatatablePagination";
import ListPageHeading from "../../../containers/navs/ListPageHeading";
import { firestore } from '../../../helpers/Firebase';
import IntlMessages from "../../../helpers/IntlMessages";
import Outlet from "./outlet";
import './outletsList.css';

import moment from "moment";
import { MenuItem } from "react-contextmenu";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import { accessTypes } from "../../../helpers/Utils";
import SelectedProvider from "../dashboard/components/SelectedServiceProvider";

const providersStatusOptions = [{ key: "all", value: "All", label: "All" }, { key: "active", value: "Active", label: "Active" }, { key: "closed", value: "Closed", label: "Closed" }]
const deviceWidth = () => {
  const width = window.innerWidth;
  const breakpoint = 820;
  return width < breakpoint ? 'mobile' : 'desktop';
}
const TheadComponent = props => null; // a component returning null (to hide) or you could write as per your requirement

class OutletsList extends Component {
  constructor(props) {
    super(props);
    this.mouseTrap = require('mousetrap');
    this.state = {
      search: "",
      isLoading: true,
      selectOutlet: false,
      selectedOutlet: {},
      allServiceProviders: [],
      addNewBranch: false,
      deleteServiceProvider: false,
      dropdownOpen: false,

    };
  }

  toggle = () => this.setState({ dropdownOpen: !this?.state?.dropdownOpen });

  dataTableColumns() {
    let { authUser, providers } = this.props
    let reviewData = this.state.analyticsData
    return ([
      {
        sortable: false,
        accessor: "active",
        width: 80,
        show: accessTypes.includes(authUser.user.accessType),
        Cell: props => <p className="list-item-heading">
          <Switch
            className="custom-switch custom-switch-primary"
            checked={props.value}
            onChange={(switchCheckedPrimary, event) => {
              event.stopPropagation(); // Stop event propagation
              providers[props.original.key].active = switchCheckedPrimary
              this.setState({ isLoading: true })
              firestore().collection('service_provider').doc(props.original.key).update({
                active: switchCheckedPrimary,
                updated: Date.now()
              })
            }}
          />
        </p>
      },
      {
        sortable: false,
        accessor: "pictures",
        width: deviceWidth() === 'desktop' ? 100 : 80,
        Cell: props => <div className="providerfieldText"><img style={{ width: '60px', height: '60px', borderRadius: '200px', border: '1px solid lightgrey', padding: '2px', objectFit: 'cover' }} src={props.value && props.value[0] ? props.value[0] : OutletImg} alt={props.value} /></div>
      },
      {
        Header: "ID",
        accessor: "uniqueID",
        show: accessTypes.includes(authUser?.user?.accessType),
        Cell: props => <p className="providerfieldText">{props.value}</p>
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: props => <p className="providerfieldText">{props.value}</p>
      },
      // {
      //   Header: "Rating",
      //   accessor: "rating",
      //   show: authUser.user && authUser.user.accessType === 'superAdmin',
      //   Cell: props => <p className="providerfieldText">{props.value}</p>
      // },
      {
        Header: "Arabic Name",
        accessor: "name_ar",
        show: !accessTypes.includes(authUser?.user?.accessType) && deviceWidth() === 'desktop' ? true : false,
        Cell: props => <p className="providerfieldText">{props.value}</p>
      },
      {
        Header: "Email",
        accessor: "email",
        show: deviceWidth() === 'desktop' ? true : false,
        Cell: props => <p className="providerfieldText">{props.value}</p>
      },
      {
        Header: "Mobile Number",
        accessor: "number",
        show: deviceWidth() === 'desktop' ? true : false,
        Cell: props => <p className="providerfieldText">{props.value}</p>
      },
      {
        Header: "Joining Date",
        accessor: "joiningDate",
        show: accessTypes.includes(authUser?.user?.accessType) && deviceWidth() === 'desktop' ? true : false,
        Cell: props => <p className="providerfieldText">{props.value ? moment(props.value).format('DD/MM/YYYY') : '--'}</p>
      },
      {
        Header: "Expiry Date",
        accessor: "expiryDate",
        show: accessTypes.includes(authUser?.user?.accessType) && deviceWidth() === 'desktop' ? true : false,
        Cell: props => <p className="providerfieldText">{props.value ? moment(props.value).format('DD/MM/YYYY') : '--'}</p>
      },

      {
        Header: "Average Rating",
        accessor: "key",
        show: (deviceWidth() === 'desktop' ? true : false) && accessTypes.includes(authUser?.user?.accessType),
        sortMethod: (a, b) => {
          let providerData = reviewData?.[a]
          let scoreA = (Number(providerData?.totalRate?.rate || 0) / Number(providerData?.totalRate?.count || 1)).toFixed(2) || 0
          let providerDataB = reviewData?.[b]
          let scoreB = (Number(providerDataB?.totalRate?.rate || 0) / Number(providerDataB?.totalRate?.count || 1)).toFixed(2) || 0
          return scoreB - scoreA; // Sort by review score within the same status
        },
        Cell: props => {
          let providerData = reviewData?.[props?.value]
          let score = (Number(providerData?.totalRate?.rate || 0) / Number(providerData?.totalRate?.count || 1)).toFixed(2) || 0
          return (
            <p className="providerfieldText">{score}</p>
          )
        }
      },
      authUser?.user?.accessType === 'superAdmin' ? {
        width: 80,
        show: deviceWidth() === 'desktop' ? true : false,
        accessor: "delete",
        sortable: false,
        Cell: props => <p className="providerfieldText">
          <Button
            color="danger"
            size="sm"
            outline
            onClick={(e) => {
              e.stopPropagation()
              this.setState({ deleteServiceProvider: true, selectedOutlet: props.original })
            }}>
            Delete
          </Button>
        </p>
      } : {
        width: 0,
        accessor: "delete",
      },
      {
        width: 70,
        accessor: "edit",
        sortable: false,
        Cell: props => <p className="providerfieldText">
          <Button
            color="primary"
            size="sm"
            outline
            className="top-right-button"
            onClick={(e) => {
              e.stopPropagation()
              this.onClickItem(props.original)
            }}
          >
            <IntlMessages id="Edit" />
          </Button>
        </p>
      }
    ])


  }
  async getServiceProvidersList() {
    let { providers } = this.props
    let { search } = this.state
    let allServiceProviders = []
    providers && Object.keys(providers).forEach(function (doc) {
      let serviceProvider = providers[doc];
      serviceProvider.key = doc
      allServiceProviders.push(serviceProvider)
    });

    let filteredBranches = allServiceProviders.filter(item =>
    (
      item?.name?.toLowerCase()?.includes(search.toLowerCase()) ||
      item?.email?.toLowerCase()?.includes(search.toLowerCase()) ||
      (item?.number?.replaceAll(' ', '')?.toLowerCase()?.includes(search.toLowerCase())) ||
      (item?.uniqueID?.includes(search)))
    );
    let analyticsSnap = await firestore().collection('newAnalytics').doc("SP").get();

    this.setState({ allServiceProviders, filteredBranches, analyticsData: analyticsSnap?.data() || {}, isLoading: false, selectOutlet: false, selectedOutlet: false, addNewBranch: false, deleteServiceProvider: false })
  }


  componentDidUpdate(previousProps) {
    let { providers } = this.props

    if (previousProps.providers !== providers) {
      this.getServiceProvidersList()
    }
  }
  componentDidMount() {
    this.getServiceProvidersList()
  }

  toggleModal = () => {
    let { selectOutlet } = this.state
    this.setState({ selectOutlet: !selectOutlet, selectedOutlet: {}, addNewBranch: true })


  };



  onClickItem = (branch) => {
    let { selectOutlet } = this.state
    this.setState({ selectOutlet: !selectOutlet, selectedOutlet: branch, addNewBranch: false })
  };

  deleteServiceProvider(serviceProvider) {
    let _this = this
    firestore().collection('service_provider').doc(serviceProvider.key).update({
      deActivated: true,
      updated: Date.now(),
      active: false
    }).then(() => {
      _this.getServiceProvidersList()
    })
  }

  filterProvidersList() {
    let { allServiceProviders, joiningRange, expiringRange, providersStatus } = this.state;
    console.log('joiningRange', joiningRange);
    console.log('expiringRange', expiringRange);
    let filteredBranches = allServiceProviders?.filter(provider => {
      const isActive = providersStatus && providersStatus !== 'all'
        ? (providersStatus === 'active' ? provider.active : !provider?.active)
        : true;

      const isExpiryDateValid = provider?.expiryDate
        ? (expiringRange?.length ? moment(provider.expiryDate).isBetween(moment(expiringRange[0]), moment(expiringRange[1])) : true)
        : false;

      const isJoiningDateValid = provider?.joiningDate
        ? (joiningRange?.length ? moment(provider.joiningDate).isBetween(moment(joiningRange[0]), moment(joiningRange[1])) : true)
        : false;

      return isActive && isExpiryDateValid && isJoiningDateValid;
    });

    this.setState({ filteredBranches });
  }
  onSearchKey = e => {
    let { filteredBranches, allServiceProviders } = this.state
    let search = e.target.value
    filteredBranches = allServiceProviders.filter(item =>
    (
      item?.name?.toLowerCase()?.includes(search.toLowerCase()) ||
      item?.email?.toLowerCase()?.includes(search.toLowerCase()) ||
      (item?.number?.replaceAll(' ', '')?.toLowerCase()?.includes(search.toLowerCase())) ||
      (item?.uniqueID?.includes(search)))
    );

    this.setState({ filteredBranches, search }
    );
  };
  exportCSV() {

    let { filteredBranches } = this.state
    let prefix = "data:text/csv;charset=utf-8,";

    let header = "ID,Service provider, Email, Number, IBAN, Account Number, Account Name, Branch, active";

    let csvContent = header + "\r\n";

    filteredBranches.map(item => {
      csvContent += `${item.uniqueID}, ${item.name}, ${item?.email || '--'}, ${item?.number || '--'}, ${item?.bankDetails?.IBAN || '--'}, ${item?.bankDetails?.accountNumber || '--'}, ${item?.bankDetails?.accountName || '--'}, ${item?.bankDetails?.branch || '--'}, ${item.active}\r\n`;

      return false
    })
    var encodedUri = prefix + encodeURIComponent(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "ServiceProvidersList.csv");
    document.body.appendChild(link);
    link.click();
  }
  render() {

    const {
      selectOutlet,
      selectedOutlet,
      addNewBranch,
      deleteServiceProvider,
      filteredBranches,
      selectedProvider, analyticsData,
      dropdownOpen
    } = this.state;
    let { authUser, services, washers } = this.props

    // let filteredBranches = allServiceProviders.filter(item => {
    //   return (item.name).toLowerCase().includes(search.toLowerCase() && (item.active === filterBoolean))
    // })
    if (selectedProvider) {
      return <SelectedProvider authUser={authUser} provider={selectedProvider} washers={washers} selectProvider={(selectedProvider) => {
        this.setState({ selectedProvider })
      }} />
    }
    return (selectOutlet ? <Outlet
      selectedOutlet={selectedOutlet}
      onClickItem={this.onClickItem}
      addNewBranch={addNewBranch}
      authUser={authUser}
      updateProvider={() => this.getServiceProvidersList()}
      services={services}
    /> :
      <Fragment>
        <div className="disable-text-selection">

          {this.state.isLoading ?
            <div className="loading" /> : ''
          }
          <ListPageHeading
            heading={authUser && authUser.user && authUser.user.accessType === 'admin' ? "Profile" : "Service Providers"}
            onSearchKey={this.onSearchKey}
            toggleModal={this.toggleModal}
            accessType={authUser.user.accessType}
          />


          <Row className="survey-app">
            {accessTypes.includes(authUser?.user?.accessType) ? <Colxx xxs="12" md="4">

              <Dropdown isOpen={dropdownOpen} toggle={this.toggle} >

                <DropdownToggle size='sm' color="primary">
                  Filter Service Providers
                </DropdownToggle>
                <DropdownMenu bottom size='xl' style={{ width: '350px' }}>

                  <DropdownItem header>
                    Filter By Status
                  </DropdownItem>
                  <MenuItem >
                    <Select
                      components={{ Input: CustomSelectInput }}
                      className="basic-multi-select"
                      placeholder="Filter Service Providers"
                      value={providersStatusOptions.find(option => option.key === this.state.providersStatus)}
                      options={providersStatusOptions}
                      onChange={val => {
                        this.setState({ providersStatus: val.key }, () => {
                          this.filterProvidersList()
                        })
                      }}
                    />
                  </MenuItem>
                  <DropdownItem header>
                    Filter By Joining Date
                  </DropdownItem>
                  <MenuItem >
                    <DatePicker
                      onChange={(dates) => {
                        let filterDates = dates?.filter(date => date)
                        this.setState({ joiningRange: filterDates }, () => {
                          this.filterProvidersList()
                        })
                      }}
                      placeholderText="Select Date"
                      isClearable={true}
                      startDate={this.state?.joiningRange?.[0]}
                      endDate={this.state?.joiningRange?.[1]}
                      selectsRange={true}
                      dateFormat={'dd/MM/yyyy'}
                    />
                  </MenuItem>
                  <DropdownItem header>
                    Filter By Expiry Date
                  </DropdownItem>
                  <MenuItem >
                    <DatePicker
                      onChange={(dates) => {
                        let filterDates = dates?.filter(date => date)
                        this.setState({ expiringRange: filterDates }, () => {
                          this.filterProvidersList()
                        })
                      }}

                      isClearable={true}
                      placeholderText="Select Date"
                      startDate={this.state?.expiringRange?.[0]}
                      endDate={this.state?.expiringRange?.[1]}
                      selectsRange={true}
                      dateFormat={'dd/MM/yyyy'}
                    />
                  </MenuItem>
                </DropdownMenu>
              </Dropdown>
            </Colxx> : ''}
            {authUser?.user?.accessType === 'superAdmin' ? <Colxx xxs="12" md="8">
              <Button className='btn-sm float-right' style={{ height: '35px' }} onClick={() => this.exportCSV()}>Export</Button>
            </Colxx> : <></>}
            <Colxx xxs="12">
              {
                filteredBranches && filteredBranches.length ?
                  <ReactTable
                    data={filteredBranches}
                    columns={this.dataTableColumns()}
                    defaultPageSize={10}
                    pageSize={filteredBranches.length < 10 ? filteredBranches.length : 10}
                    showPageJump={false}
                    showPageSizeOptions={false}
                    PaginationComponent={DataTablePagination}
                    className='custuomReactTable'
                    TheadComponent={(deviceWidth() === 'mobile') ? TheadComponent : undefined}
                    getTdProps={(state, rowInfo, column, instance) => {
                      return {
                        onClick: (e, handleOriginal) => {
                          // Your action here. For example, logging the row and column
                          console.log("Cell - Row Info:", rowInfo, "Column:", column);
                          // If you want to trigger the original onClick event as well
                          let providerAnalyticsData = analyticsData?.[rowInfo?.original?.key]
                          console.log("providerAnalyticsData", rowInfo?.original);
                          console.log("providerAnalyticsData", analyticsData);
                          this.setState({ selectedProvider: { ...rowInfo.original, ...providerAnalyticsData } })
                          if (handleOriginal) {
                            handleOriginal();
                          }
                        }
                      };
                    }}
                  />
                  :
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>No Service Providers</div>
              }
            </Colxx>
          </Row>
          <Modal backdrop="static" isOpen={deleteServiceProvider}>
            <ModalHeader >Delete Service Provider</ModalHeader>
            <ModalBody>
              <Row>
                <Colxx xxs="12" className="mb-4">
                  <Label className="mb-2">Are you sure you want to delete this Service Provider?</Label>
                </Colxx>
              </Row>

            </ModalBody>
            <ModalFooter>
              <Button onClick={() => this.setState({ deleteServiceProvider: false, selectedOutlet: {} })}>Dismiss</Button>
              <Button color='danger' onClick={() => this.setState({ isLoading: true }, () => { this.deleteServiceProvider(selectedOutlet) })}>Delete Service Provider</Button>
            </ModalFooter>

          </Modal>

        </div>
      </Fragment>
    )
  }
}
const mapStateToProps = ({ providers, authUser }) => {
  return {
    authUser,
    providers: providers ? providers.provider : {},
    washers: providers ? providers.washers : {},
    services: providers ? providers.services : {},

  };
};



export default connect(mapStateToProps)(OutletsList);

